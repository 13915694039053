import { types } from "mobx-state-tree"
import * as views from './TrainingViews'
import * as actions from './TrainingActions'
import Product from "../product/Product"

const Training = types
  .model('Training', {
    id: types.identifierNumber,
    status: types.maybeNull(types.string),
    coverUrl: types.maybeNull(types.string),
    triggerWord: types.maybeNull(types.string),

    product: types.maybeNull(types.reference(Product), null)
  })
  .views(views.trainingViews)
  .actions(actions.trainingActions)

export default Training