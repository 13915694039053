import { _throw } from "../../../utils/error-service"
import { toJS } from "mobx"
import { api_update_product } from "../../../queries/product"

export const productActions = self => ({

  update(product) {
    console.log("Update product")
    console.log(product)

    self.set('status', product.status)
    self.set('productType', product.productType)
    self.set('title', product.title)
    self.set('description', product.description)
    self.set('sku', product.sku)
    self.set('type', product.type)
  },
  async updateProduct() {
    try {
      const p = toJS(self)

      const res = await api_update_product(self.accountStore.auth0AccessToken, {
        id: p.id,
        productType: p.tempType
      })

      self.productsFactory.addUpdateProduct(res.data.data)

      return res.data.data
    } catch (err) {
      return err
    }
  
  },
  guessProductType(productType) {

    if (self.productType === "product" && self.tempType === "product") {
      self.set("productType", productType)
      self.set("tempType", productType)
    } else {
      self.set("guessedType", productType)
    }
  },
  setGuessedType() {
    self.set("productType", self.guessedType)
    self.set("tempType", self.guessedType)
    self.set("guessedType", null)
    self.updateProduct()
  },
  set(key, value) {
    self[key] = value
  }
})