import { useAuth0 } from "@auth0/auth0-react";
import styled from '@emotion/styled';
import { Box, Typography, useMediaQuery } from '@mui/material';
import logo from '../../assets/logo-relum.png';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { API } from '../../constants/system';
import DropdownMenu from './DropdownMenu';
import { useNavigate } from 'react-router-dom';
import BasicMenu from "../BasicMenu/BasicMenu";
import Tokens from "./Tokens";
import { TABS } from "../../constants/status";
import ProBadge from "../Tags/ProBadge";
import AdminBadge from "../Tags/AdminBadge";
import Button from "../Button/Button";
import Credits from "../Credits/Credits";
import Badge from "../Tags/Badge";
import { useState } from "react";

const Header = styled(Box)({
  height: '50px',
  backgroundColor: '#171717',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingRight: '10px',
  paddingLeft: '20px',
  position: 'fixed',
  left: 0,
  right: 0,
  top: 0,
  zIndex: 900
});



function ApplicationHeader(props) {
  const { logout } = useAuth0();
  const { generateImageStore, accountStore, guiStore } = props.store 

  const [loading, setLoading] = useState(false);

  const Navigate = useNavigate();

  const isMobile = useMediaQuery('(max-width:500px)');

  const handlePortalClick = async (type) => {
    try {

      setLoading(true)

      let typeQ = ``
      if (type) typeQ = `?flow=${type}`

      const { data } = await axios.get(`${API}/create-customer-portal-session${typeQ}`,  {
        headers: {
          'Authorization': `Bearer ${accountStore.auth0AccessToken}`
        } 
      });

      window.location.href = data.data.url
    } catch (error) {
      console.error('Failed to open customer portal', error);
      setLoading(false)
    }
  };
  

  const logOutUser = () => {
    logout({ logoutParams: { returnTo: window.location.origin } })
    accountStore.logOutUser()
  };

  const tabSelect = (tab) => {
    // if (guiStore.selectedTab === tab) return guiStore.set('selectedTab', TABS.NONE)
    // guiStore.set('selectedTab', tab)
    if (tab === TABS.SCENE) {
      guiStore.set('selectedTab', TABS.SCENE)
    } else if (tab === TABS.PRODUCTS) {
      guiStore.openProductsModal()
    } else if (tab === TABS.TEMPLATES) {
      guiStore.openPresetsModal()
    } else if (tab === TABS.SETTINGS) {
      guiStore.openSettingsModal()
    }
  }


  return (
      <Header>
        <Box sx={{cursor: 'pointer'}} display={"flex"} flexGrow={1} justifyContent={"space-between"} >
          <Box display={'flex'} justifyContent={'center'} justifyItems={'center'} flexDirection={'row'} onClick={() => Navigate('/')}>
            <img src={logo} alt="ProductAi Logo" height={"40px"} padding={"25px"} />
            {accountStore.isPro && !accountStore.isEnterprise && <>
              <Box sx={{ margin: "0 5px" }}>
                <ProBadge />
              </Box>
            </>}
            {accountStore.isAdmin && <>
              <Box sx={{ margin: "0 5px" }}>
                <AdminBadge />
              </Box>
            </>}
            {
              accountStore.isTrial && <>
                <Box sx={{ margin: "0 5px" }}>
                  <Badge>TRIAL</Badge>
                </Box>
              </>
            }
          </Box>
          {/* {accountStore.isAdmin ?
            <Credits 
              productsLeft={accountStore.productsLeft}
              tokens={accountStore.tokensLeft}
              scenesLeft={accountStore.scenesLeft}
              allProducts={accountStore.products.length}
            /> : null
          } */}
          <Box display={"flex"} alignItems={"center"}>
            {/* {accountStore.isEnterprise || props.noTokens ? null :
              <Tokens
                value={accountStore.tokens} 
                openTokens={() => {
                  if (accountStore.tokens === 0)
                    guiStore.openPaywallModal()
                  else
                    guiStore.openTokensModal()
                }}
                loading={accountStore.loading}
              />
              
            } */}
            {accountStore.isTrial &&
              <Button
                height="40px"
                width="116px"
                mini
                backgroundColor={"#82FFB4"}
                onClick={() => handlePortalClick("subscription_update")}
                loading={loading}
              >
                Upgrade plan
              </Button>
            }
            { !isMobile && <BasicMenu 
                billing={handlePortalClick}
                logout={logOutUser}
                tokens={accountStore.tokens}
                tokensLeft={guiStore.openTokensModal}
              />
            }
            { isMobile &&
              <Box paddingLeft={"15px"}>
                <DropdownMenu 
                  logout={logOutUser} 
                  billing={handlePortalClick} 
                  help={() => guiStore.openHelpModal()}
                  tabSelect={tabSelect}
                  selectedTab={guiStore.selectedTab}
                  tokens={accountStore.tokens}
                  tokensLeft={guiStore.openTokensModal}
                />
              </Box>
            }
          </Box>
        </Box>
      </Header>
        
  );
}

export default inject('store')(observer(ApplicationHeader))

const EnterpriseText = styled.p({
  color: "white",
  margin: "0 5px"
})
