import axios from 'axios'
import { _throw } from "../../../utils/error-service"
import { API } from "../../../constants/system"
import { toJS } from 'mobx'

export const trainingActions = self => ({
  update(training) {
    self.set("status", training.status)
    self.set("coverUrl", training.coverUrl)
    self.set("triggerWord", training.triggerWord)
  },
  set(key, value) {
    self[key] = value
  }
})