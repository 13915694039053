import { api_get_trainings, api_train } from "../../../queries/train";

export const trainStoreActions = self => ({  

  async train(files) {
    try {
      self.set("loading", true)
      const formData = new FormData();

      // Append each file to the FormData object under the name 'files'
      
      formData.append('triggerWord', self.productContext);
      files.forEach((fileData) => {
        formData.append('files', fileData.file);
      });

      const res = await api_train(self.accountStore.auth0AccessToken, formData) 

      console.log("🏋️ training response")
      console.log(res)


      self.set("loading", false)
      return res

    } catch (err) {
      self.set("loading", false)
      console.log(err)
    }
  },

  async getTrainings() {
    try {
      const res = await api_get_trainings(self.accountStore.auth0AccessToken)

      console.log("🏋️ getTrainings response")
      console.log(res)

      self.set('trainings', res.data.data.map((training) => ({
        ...training,
        product: self.productsFactory.addUpdateProduct(training.product)

      })))
    } catch (err) {
      console.log(err)
    }
  },

  set(key, value) {
    self[key] = value
  }

})

