import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

//import SidebarLayout from './layouts/SidebarLayout';
import BaseLayout from './layouts/BaseLayout';

import SuspenseLoader from './components/SuspenseLoader';
import LoaderPage from './content/pages/LoaderPage/LoaderPage';
// import ProtectedRouteMerchant from './routes/ProtectedRouteMerchant';
// import ProtectedRouteSupportOrAdmin from './routes/ProtectedRouteSupportOrAdmin';
// import ProtectedOnlyAdmin from './routes/ProtectedOnlyAdmin';
import ProtectedOnlyCustomer from './routes/ProtectedOnlyCustomer';
import ProtectedOnlyIsLogedIn from './routes/ProtectedOnlyIsLogedIn';
import Enterprise from './content/application/enterprise';


const Loader = (Component) => (props) => (
  <Suspense>
    <Component {...props} />
  </Suspense>
);


// Dashboards

const Login = Loader(lazy(() => import('./content/pages/Login/LoginScreen')));
const Homebase = Loader(lazy(() => import('./content/application/Homebase')));
const PricingPage = Loader(lazy(() => import('./content/application/pricingPage')));
const PaymentSucces = Loader(lazy(() => import('./content/application/paymentSucces')));
const MergeSuccess = Loader(lazy(() => import('./content/application/mergeSuccess')));
const SignUp = Loader(lazy(() => import('./content/pages/signUp/SignUp')));
const TrainPage = Loader(lazy(() => import('./content/application/trainPage')));

// Status

// const Status404 = Loader(lazy(() => import('./content/pages/Status/Status404')));
// const Status500 = Loader(lazy(() => import('./content/pages/Status/Status500')));
// const StatusComingSoon = Loader(lazy(() => import('./content/pages/Status/ComingSoon')));
// const StatusMaintenance = Loader(lazy(() => import('./content/pages/Status/Maintenance')));
const NoAccess= Loader(lazy(() => import('./content/pages/Status/NoAccess')));


const routes = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboard" />
      },
      {
        path: 'off-limits',
        element: <NoAccess />
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'checkout',
        element: <SignUp />
      },
      {
        path: 'signup',
        element: <SignUp />
      },
      {
        path: 'welcome',
        element: (
          <ProtectedOnlyIsLogedIn>
            <PricingPage />
          </ProtectedOnlyIsLogedIn>
        )
      },
      {
        path: 'enterprise',
        element: (
          <ProtectedOnlyIsLogedIn>
            <Enterprise />
          </ProtectedOnlyIsLogedIn>
        )
      },
      {
        path: 'payment-success',
        element: <PaymentSucces />
      },
      {
        path: 'merge-success',
        element: <MergeSuccess />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: (
              <Navigate
                to="404"
                replace
              />
            )
          },
          {
            path: '404',
            element: <NoAccess />
          },
          {
            path: '500',
            element: <NoAccess />
          },
          {
            path: 'maintenance',
            element: <NoAccess />
          },
          {
            path: 'coming-soon',
            element:<NoAccess />
          },
        ]
      },
      {
        path: '/train',
        element: (
          <ProtectedOnlyIsLogedIn>
            <TrainPage />
          </ProtectedOnlyIsLogedIn>
        )
      },
      {
        path: '*',
        element: <NoAccess />
      },
    ] 
  },
  {
    path: 'dashboard',
    children: [
      {
        path: '',
        element: (
          <ProtectedOnlyCustomer>
            <Navigate
              to="/dashboard/homebase"
              replace
            />
          </ProtectedOnlyCustomer>
        )
      },
      {
        path: 'homebase',
        element: (
          <ProtectedOnlyCustomer>
            <Homebase />
          </ProtectedOnlyCustomer>
        ),
      },
    ]
  },

  
];

export default routes;
