import axios from "axios"
import { _throw } from "../utils/error-service"
import { API } from '../constants/system'

export const api_train = async (token, data) => {
  try {
    const res = await axios.post(`${API}/train`, data, {
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_trainings = async (token, status) => {
  try {
    const qS = status ? `?status=${status.join(",")}` : ''

    const res = await axios.get(`${API}/my-trainings${qS}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}